import { firebase } from '../../firebase/setup';

export default class TelemetryHelper {
  constructor(streamUid) {
    if (!streamUid) throw Error('TelemetryHelper needs a stream uid');
    this.streamUid = streamUid;
    this.telemetryData = {};

    this.currentTimestamp = 0;
    this.lastDoc = undefined;
    this.limit = 5;
    this.hasMoreData = true;
  }

  retrieveData(videoProgressInSeconds, reset = false, isEmojiBarExpanded = true) {
    return new Promise((resolve, reject) => {
      // Reset is true when user is seeking in a video
      if (reset) {
        // console.log('retrieveData RESET ' + reset);
        this.hasMoreData = true;
        this.lastDoc = undefined;
      }

      if (!this.hasMoreData) {
        return resolve(null);
      }

      // Do not show emojis if emoji bar is collapsed
      if (!isEmojiBarExpanded) {
        return resolve(null);
      }

      this.currentTimestamp = videoProgressInSeconds;
      // console.log('currentTimestamp', this.currentTimestamp);

      // If we know there is more data lets check if there is data for current doc id eg.: s_387456
      if (this.lastDoc && this.lastDoc.data().timestamp) {
        if (this.currentTimestamp <= this.lastDoc.data().timestamp) {
          if (this.telemetryData[`s_${this.currentTimestamp}`]) {
            // console.log('Returning telemetry data which has already been fetched for timestamp ', this.currentTimestamp);
            return resolve(this.telemetryData[`s_${this.currentTimestamp}`]);
          } else {
            // console.log(`No Telemetry data for current timestamp ${this.currentTimestamp}`);
            return resolve(null);
          }
        } else {
          // console.log(`currentTimestamp ${this.currentTimestamp} is higher than last doc timestamp`);
          if (!this.hasMoreData) {
            // console.log('No more telemetry data for stream id');
            return resolve(null);
          }
          // console.log(`No Telemetry data for current id ${this.currentTimestamp} will fetch new batch`);
        }
      }

      try {
        // console.log('Retrieving Data from firestore');

        let initialQuery;
        // Check if it's the first time we are fetching data
        if (!this.lastDoc) {
          initialQuery = firebase()
            .firestore()
            .collection('streams')
            .doc(this.streamUid)
            .collection('telemetry')
            .where('timestamp', '>=', this.currentTimestamp)
            .orderBy('timestamp')
            .limit(this.limit);
        } else {
          initialQuery = firebase()
            .firestore()
            .collection('streams')
            .doc(this.streamUid)
            .collection('telemetry')
            .orderBy('timestamp')
            .startAfter(this.lastDoc)
            .limit(this.limit);
        }

        return initialQuery.get().then((documentSnapshots) => {
          // console.log('Did fetch', documentSnapshots);
          if (documentSnapshots.size <= 0) {
            // console.log('No more telemetry for current id after fetching, setting has data false');
            this.hasMoreData = false;
            return null;
          }

          return this.handleData(documentSnapshots);
        });
      } catch (error) {
        console.error(error);
        this.hasMoreData = false;
        return reject(null);
      }
    });
  }

  handleData(documentSnapshots) {
    let documentMap = {};
    documentSnapshots.forEach((doc) => {
      documentMap[doc.id] = doc.data();
    });
    this.lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    // console.log('last dogooo', this.lastDoc.data());
    this.telemetryData = documentMap;

    if (this.telemetryData[`s_${this.currentTimestamp}`]) {
      // console.log('Returning telemetry data after fetch', this.telemetryData[`s_${this.currentTimestamp}`]);
      return this.telemetryData[`s_${this.currentTimestamp}`];
    }
    return null;
  }
}
