import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLottie } from 'lottie-react';
import thumbsUpStaticJson from '../../lottie-json/ThumbsUp_Static.json';
import thumbsUpOverJson from '../../lottie-json/ThumbsUp_Over.json';
import thumbsUpFlowJson from '../../lottie-json/ThumbsUp_Flow.json';
import thumbsUpClickJson from '../../lottie-json/ThumbsUp_Click.json';
import thumbsUpHoverJson from '../../lottie-json/ThumbsUp_Bar_Hover.json';

import heartsStaticJson from '../../lottie-json/Hearts_Static.json';
import heartsOverJson from '../../lottie-json/Hearts_Over.json';
import heartsFlowJson from '../../lottie-json/Hearts_Flow.json';
import heartsClickJson from '../../lottie-json/Hearts_Click.json';
import heartsHoverJson from '../../lottie-json/Hearts_Bar_Hover.json';

import smileyStaticJson from '../../lottie-json/Smiley_Static.json';
import smileyOverJson from '../../lottie-json/Smiley_Over.json';
import smileyFlowJson from '../../lottie-json/Smiley_Flow.json';
import smileyClickJson from '../../lottie-json/Smiley_Click.json';
import smileyHoverJson from '../../lottie-json/Smiley_Bar_Hover.json';

import shockedStaticJson from '../../lottie-json/Shocked_Static.json';
import shockedOverJson from '../../lottie-json/Shocked_Over.json';
import shockedFlowJson from '../../lottie-json/Shocked_Flow.json';
import shockedClickJson from '../../lottie-json/Shocked_Click.json';
import shockedHoverJson from '../../lottie-json/Shocked_Bar_Hover.json';

import happyStaticJson from '../../lottie-json/Happy_Static.json';
import happyOverJson from '../../lottie-json/Happy_Over.json';
import happyFlowJson from '../../lottie-json/Happy_Flow.json';
import happyClickJson from '../../lottie-json/Happy_Click.json';
import happyHoverJson from '../../lottie-json/Happy_Bar_Hover.json';

import loveStaticJson from '../../lottie-json/Love_Static.json';
import loveOverJson from '../../lottie-json/Love_Over.json';
import loveFlowJson from '../../lottie-json/Love_Flow.json';
import loveClickJson from '../../lottie-json/Love_Click.json';
import loveHoverJson from '../../lottie-json/Love_Bar_Hover.json';

import yuuckStaticJson from '../../lottie-json/Yuuck_Static.json';
import yuuckOverJson from '../../lottie-json/Yuuck_Over.json';
import yuuckFlowJson from '../../lottie-json/Yuuck_Flow.json';
import yuuckClickJson from '../../lottie-json/Yuuck_Click.json';
import yuuckHoverJson from '../../lottie-json/Yuuck_Bar_Hover.json';

const animations = {
  thumbsUp: {
    static: thumbsUpStaticJson,
    over: thumbsUpOverJson,
    flow: thumbsUpFlowJson,
    click: thumbsUpClickJson,
    hover: thumbsUpHoverJson,
  },
  hearts: {
    static: heartsStaticJson,
    over: heartsOverJson,
    flow: heartsFlowJson,
    click: heartsClickJson,
    hover: heartsHoverJson,
  },
  smiley: {
    static: smileyStaticJson,
    over: smileyOverJson,
    flow: smileyFlowJson,
    click: smileyClickJson,
    hover: smileyHoverJson,
  },
  shocked: {
    static: shockedStaticJson,
    over: shockedOverJson,
    flow: shockedFlowJson,
    click: shockedClickJson,
    hover: shockedHoverJson,
  },
  happy: {
    static: happyStaticJson,
    over: happyOverJson,
    flow: happyFlowJson,
    click: happyClickJson,
    hover: happyHoverJson,
  },
  love: {
    static: loveStaticJson,
    over: loveOverJson,
    flow: loveFlowJson,
    click: loveClickJson,
    hover: loveHoverJson,
  },
  yuuck: {
    static: yuuckStaticJson,
    over: yuuckOverJson,
    flow: yuuckFlowJson,
    click: yuuckClickJson,
    hover: yuuckHoverJson,
  },
};

const Emoji = ({ type, state = 'static', autoplay = true, onSelect, onAnimationDone, mountedElementWidth }) => {
  const [animationState, setAnimationState] = useState(state);
  const [disabled, setDisabled] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const options = {
    animationData: animations[type][animationState],
    loop: false,
    autoplay,
  };

  if (onAnimationDone) {
    options.onComplete = onAnimationDone;
  }

  const lottieInstance = useLottie(options);
  const { View } = lottieInstance;

  const onHover = () => {
    setAnimationState('hover');
    setTimeout(() => {
      if (!isMounted.current) return;
      // onComplete seemed slow - so take duration into ms
      setAnimationState('static');
    }, lottieInstance.getDuration(false) * 1000);
  };

  const clickEmoji = () => {
    setDisabled(true);
    setAnimationState('click');
    setTimeout(() => {
      if (!isMounted.current) return;
      setAnimationState('static');
    }, lottieInstance.getDuration(false) * 1000);

    if (onSelect) {
      onSelect();
    }

    setTimeout(() => {
      if (!isMounted.current) return;
      setDisabled(false);
    }, 500);
  };

  const emojiProps = {
    className: `verse-emojiButton ${type}`,
    type: 'button',
  };

  const emojiStyle = () => {
    if (state === 'flow') {
      return {
        height: mountedElementWidth >= 992 ? '1060px' : '940px',
      };
    }
  };

  if (onSelect) {
    if (!isMobile) {
      emojiProps.onMouseEnter = onHover;
    }
    emojiProps.onClick = clickEmoji;
  }

  return (
    <button disabled={disabled} type="button" {...emojiProps} style={emojiStyle()}>
      <span></span>
      {View}
    </button>
  );
};

export default Emoji;
