import axios from 'axios';
import { isLocalHost } from '../utils/urlUtil';

const LOCALHOST_BASE_URL = 'http://localhost:5001/the-verse-app-sandbox/europe-west1';
const SANDBOX_BASE_URL = 'https://europe-west1-the-verse-app-sandbox.cloudfunctions.net';
const PROD_BASE_URL = 'https://europe-west1-the-verse-app.cloudfunctions.net';

const axiosInstance = axios.create({
  baseURL: isLocalHost ? LOCALHOST_BASE_URL : PROD_BASE_URL,
});

export function setAxiosConfig(sandboxMode) {
  if (isLocalHost()) {
    axiosInstance.defaults.baseURL = LOCALHOST_BASE_URL;
  } else if (sandboxMode) {
    axiosInstance.defaults.baseURL = SANDBOX_BASE_URL;
  } else {
    axiosInstance.defaults.baseURL = PROD_BASE_URL;
  }
}

export default axiosInstance;
