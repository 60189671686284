// Todo find a better way of identifying our own localhost (i.e should not use localhost if client is implementing plugin on their localhost)
export const isLocalHost = () => {
  const isLocalHost = location.hostname === 'localhost' && location.href.indexOf('demo_sync.html') !== -1;
  // console.log('isLocalHost', isLocalHost);
  return isLocalHost;
};

export const firebaseFunctionsLocalhost = () => {
  return {
    host: 'localhost',
    port: 5001,
  };
};

export const firestoreLocalhost = () => {
  return {
    host: 'localhost',
    port: 8081,
  };
};
