import axiosInstance from './AxiosInstance';

export const createViewer = (streamUid, viewerUid) => (
  axiosInstance
    .post('/createViewer', { data: { streamUid: streamUid, viewerUid: viewerUid } }, {})
    .then((response) => {
      // console.log('Create viewer success');
      return response.data.countryCode;
    })
    .catch((err) => {
      console.error('Create viewer error', err)
      return null;
    })
)
