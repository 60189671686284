export const verseConfig = () => {
  return {
    apiKey: 'AIzaSyDJnbL8ovLyMIm3qXHJfSP1RSVzA83ygHo',
    authDomain: 'the-verse-app.firebaseapp.com',
    projectId: 'the-verse-app',
    storageBucket: 'the-verse-app.appspot.com',
    messagingSenderId: '566253634620',
    appId: '1:566253634620:web:60e87ee154912290932f39',
    measurementId: 'G-3NMMQGLTC6',
  };
};

export const verseSandboxConfig = () => {
  return {
    apiKey: 'AIzaSyCriVyWVQ4kAUuJQDMrSE_-20KLia5_0uQ',
    authDomain: 'the-verse-app-sandbox.firebaseapp.com',
    projectId: 'the-verse-app-sandbox',
    storageBucket: 'the-verse-app-sandbox.appspot.com',
    messagingSenderId: '149653015856',
    appId: '1:149653015856:web:3da5b7b4f4cf1e09244cf5',
    // measurementId: "G-3NMMQGLTC6"
  };
};
