import firebaseApp from 'firebase/app';

import { verseConfig, verseSandboxConfig } from '../../firebase-config';
import { isLocalHost, firebaseFunctionsLocalhost, firestoreLocalhost } from '../utils/urlUtil';

import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';

const firebaseAppName = 'verse-plugin-firebase';

export const firebase = (sandbox) => {
  const isAppInitialized = firebaseApp.apps.find((x) => x.name === firebaseAppName);
  if (!isAppInitialized && isLocalHost()) {
    const fa = firebaseApp.initializeApp(verseSandboxConfig(), firebaseAppName);
    // const db = firebaseApp.firestore(fa);
    // const func = firebaseApp.functions(fa);
    // db.useEmulator(firestoreLocalhost().host, firestoreLocalhost().port);
    // func.useEmulator(firebaseFunctionsLocalhost().host, firebaseFunctionsLocalhost().port);
    return fa;
  } else if (!isAppInitialized && sandbox === true) {
    return firebaseApp.initializeApp(verseSandboxConfig(), firebaseAppName);
  } else if (!isAppInitialized) {
    return firebaseApp.initializeApp(verseConfig(), firebaseAppName);
  } else {
    return firebaseApp.app(firebaseAppName);
  }
};
