import React, { useState } from 'react';
import Emoji from './Emoji';
import './emotionBar.scss';
import { BsXCircleFill } from 'react-icons/bs';

const emojis = ['hearts', 'happy', 'smiley', 'yuuck', 'shocked', 'thumbsUp', 'love'];

const disappearAnimation = [{ transform: 'scale(0)', opacity: 0 }];
const appearAnimation = [{ transform: 'scale(1)', opacity: 1 }];
const animationDuration = 500 / (emojis.length - 1);

function EmotionBar(props) {
  const onSelect = (emotionIndex) => {
    if (!props.isEmojiBarExpanded) {
      let emojiElements = [].slice.call(document.getElementsByClassName(`verse-emojiButton`));
      console.log('her');
      emojiElements
        .slice(1)
        .reverse()
        .forEach((element, index) => {
          console.log('appearing emoji', element, index);
          element.animate(appearAnimation, {
            delay: index * animationDuration,
            duration: animationDuration,
            fill: 'forwards',
          });
        });
      return onEmojiBarToggle();
    }

    const data = {
      source: 'Emoji',
      value: emotionIndex,
      date: parseInt(new Date().getTime() / 1000),
      ip: '',
    };

    props.onSelect({ data });
  };

  const onEmojiBarToggle = () => {
    const isEmojiBarExpandedReverse = !props.isEmojiBarExpanded;

    // Expand / Collapse emojiBar
    const verseEmojiBarQuery = document.getElementsByClassName(emotionBarClass());
    if (verseEmojiBarQuery && verseEmojiBarQuery.length > 0) {
      if (!isEmojiBarExpandedReverse) {
        verseEmojiBarQuery[0].classList.add('verse-collapse');
      } else {
        verseEmojiBarQuery[0].classList.remove('verse-collapse');
      }
    }

    if (props.isEmojiBarExpanded) {
      let emojiElements = [].slice.call(document.getElementsByClassName(`verse-emojiButton`));
      emojiElements.slice(1).forEach((element, index) => {
        console.log('disappearing emoji', element);
        element.animate(disappearAnimation, {
          delay: (index - 2) * animationDuration,
          duration: index < 2 ? 0 : animationDuration,
          fill: 'forwards',
        });
      });
    }

    // Tell parent (EmojiStream.js) that emojiBar has been toggled so it doesnt show new emojis displayed
    props.onEmojiBarToggle();
  };

  function emotionBarClass() {
    console.log('emojiiiiii ', props.emojiBarClass);
    let emotionBarClass = 'verse-emotion-bar';
    if (props.align === 'bottom-right') {
      emotionBarClass = 'verse-emotion-bar';
    } else if (props.align === 'center-right') {
      emotionBarClass = 'verse-emotion-bar-vertical';
    }
    return emotionBarClass + ' ' + props.emojiBarClass;
  }

  return (
    <div className={emotionBarClass()}>
      {props.isEmojiBarExpanded ? (
        <div className="emoji-close-btn" onClick={() => onEmojiBarToggle()}>
          <BsXCircleFill color="white" size="24"></BsXCircleFill>
        </div>
      ) : null}
      <span id="emoji-bar-background" className="background"></span>
      <Emoji type={emojis[0]} onSelect={() => onSelect(0)} />
      {emojis.map((emoji, index) => (
        <Emoji key={emoji} type={emoji} onSelect={() => onSelect(index)} />
      ))}
    </div>
  );
}

export default EmotionBar;
