import EmojiStream from './components/widget/EmojiStream';

import React from 'react';
import ReactDOM from 'react-dom';
import Emitter from './utils/events/eventEmitter';

let globalConfig = {};

export default {
  widgets: {
    emojiBar: {
      new: (config) => {
        return {
          render: () => {
            let selectorId = config.selector || globalConfig.selector;
            let selectorElement = document.querySelector(selectorId);
            if (!selectorId || !selectorElement) {
              console.error('VersePlugin: Unable to find element to attach');
              return;
            }

            config.emojiStreamWidget = (
              <EmojiStream
                streamUid={config.streamUid || globalConfig.streamUid}
                align={config.align || globalConfig.align}
                hideEmojiControls={config.hideEmojiControls || globalConfig.hideEmojiControls}
                enableMoodEmojiBurst={config.enableMoodEmojiBurst || globalConfig.enableMoodEmojiBurst}
                selectorId={selectorId}
                sandbox={config.sandbox || globalConfig.sandbox}
                forceShowEmojisOnPlayback={config.forceShowEmojisOnPlayback || globalConfig.forceShowEmojisOnPlayback}
              />
            );

            // Make the whole div that this plugin is attached to non-clickable. This is so it does not
            // override any functionality that the customer might have on their stream. Later in this
            // plugin only the emojiBar will be made clickable.
            selectorElement.style.pointerEvents = 'none';

            // Finally render the plugin
            ReactDOM.render(config.emojiStreamWidget, selectorElement);
          },

          unmount: () => {
            let selectorElement = document.querySelector(config.selector);
            if (selectorElement) {
              ReactDOM.unmountComponentAtNode(selectorElement);
            }
          },

          /**
           * Call when:
           * Video is being started/resumed
           * Video is being seeked and running
           */
          setVideoProgress: (progress) => {
            Emitter.emit(Emitter.EVENTS.VIDEO_PROGRESS, progress);
          },

          /**
           * Call when:
           * Video is being stopped
           */
          videoStopped: () => {
            Emitter.emit(Emitter.EVENTS.VIDEO_STOP);
          },
        };
      },
    },
  },
};
