const AsyncLock = require('async-lock');
const lock = new AsyncLock({ maxOccupationTime: 100 });
const lockKey = 'isMaxAmountExceeded';

export default class EmojiHelper {
  constructor() {
    this.maxAmountOfNewlyAddedEmojisPerSecond = 5;
    this.currentAmountOfNewlyAddedEmojis = new Map();
  }

  isMaxAmountOfEmojisExceeded() {
    let amountExceeded = true;
    lock.acquire(
      lockKey,
      (done) => {
        // console.log("lock1 enter+++++");

        const currentSecond = Math.floor(new Date().getTime() / 1000);
        // console.log('current second', currentSecond);
        let currAmountOfNewlyAddedEmojis = this.currentAmountOfNewlyAddedEmojis.get(currentSecond);
        // console.log('emojis in current second', currAmountOfNewlyAddedEmojis || 0);
        if (!currAmountOfNewlyAddedEmojis) {
          this.currentAmountOfNewlyAddedEmojis.clear();
          this.currentAmountOfNewlyAddedEmojis.set(currentSecond, 1);
          amountExceeded = false;
          // console.log('rendering emoji since its first in current time');
        } else if (currAmountOfNewlyAddedEmojis <= this.maxAmountOfNewlyAddedEmojisPerSecond) {
          this.currentAmountOfNewlyAddedEmojis.set(currentSecond, currAmountOfNewlyAddedEmojis + 1);
          amountExceeded = false;
          // console.log('rendering emoji since we didnt reach cap');
        } else {
          amountExceeded = true;
          // console.log('continue - too many emoji in current second');
        }

        done();
      },
      (err, ret) => {
        if (err) amountExceeded = true;
        // console.log("lock1 release-----");
      },
      {}
    );
    return amountExceeded;
  }
}
