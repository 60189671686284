import React from 'react';
import './floatingEmojiContainer.scss';
import EmotionBar from '../emojibar/EmotionBar';
import Emoji from '../emojibar/Emoji';
import Emitter from '../../utils/events/eventEmitter';
import { detectAnimationEndEventName } from '../../utils/events/detectEventName';
import { emojiTypes, getColor } from '../../constants';
import { randomIntFromInterval } from '../../utils/mathUtils';

import { firebase } from '../../firebase/setup';
import { setAxiosConfig } from '../../service/AxiosInstance';

import telemetryHelper from './TelemetryHelper';
import emojiHelper from './EmojiHelper';
import { createViewer } from '../../service/ViewerService';

const animationEndEventName = detectAnimationEndEventName();
const bubbleSizes = [2, 3, 4, 5, 6, 7, 8, 9, 10];
const emojiSizes = [20, 25, 30];
const showEmojiInterval = 5;

class EmojiStream extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newlyAddedView: [],
      origViews: [],
      floatingEmojis: [],
      otherPeoplesFloatingEmojis: [],
      views: [],
      bubbles: [],
      duration: 0,
      isLiveStream: true,
      showEmojisOnPlayback: true,
      isRerunOfLiveStream: false,
      moodFloatingEmojis: [],
      isEmojiBarExpanded: true,
      testArray: [],
      emojiBarClass: '',
    };

    // Other people's newly created emoji's
    this.totalEmojis = {};
    this.newAddedBubblesRefs = [];
    this.newlyAddedCounter = 0;

    // Playback
    this.currentSecond = 0;
    this.bubblesRefs = [];
    this.bubbles = {};
    this.bubbleInterval = undefined;
    this.emojiBubbleCounter = 0;
    this.resetPlaybackData = false;
    this.maxBubblesAmount = 10;

    // Emoji burst
    this.moodEmojiBurstInterval = undefined;
    this.moodBubblesRefs = [];
    this.initialMoodScore = {};
    this.moodEmojiCounter = 0;

    this.floatingEmojiCounter = 0;

    // User logged in uid
    this.uid = undefined;
    this.userCountryCode = undefined;

    // Configurable props
    this.streamUid = this.props.streamUid;
    this.validStreamUid = false;
    this.selectorId = this.props.selectorId;
    this.align = this.props.align || 'bottom-right';
    this.hideEmojiControls = this.props.hideEmojiControls;
    this.enableMoodEmojiBurst = this.props.enableMoodEmojiBurst || false;
    this.forceShowEmojisOnPlayback = this.props.forceShowEmojisOnPlayback || false;
    this.emojiHelper = new emojiHelper();

    this.initialize(this.props.sandbox || false);
  }

  initialize(sandbox) {
    this.firebase = firebase(sandbox);
    setAxiosConfig(sandbox);
  }

  listenToEvents() {
    // console.log('Listening to events');
    Emitter.on(Emitter.EVENTS.VIDEO_PROGRESS, (value) => {
      if (isNaN(value)) {
        console.error('Event VIDEO_PROGRESS needs a value');
        return;
      }
      // console.log('EVENT VIDEO_PROGRESS with value', value);
      this.currentSecond = value;
      this.resetPlaybackData = true;
      this.setupBubbleInterval();
    });
    Emitter.on(Emitter.EVENTS.VIDEO_STOP, () => {
      // console.log('EVENT VIDEO_STOP');
      clearInterval(this.bubbleInterval);
    });
  }

  componentDidMount() {
    this.listenToEvents();

    this.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.uid = user.uid;
        this.firebase
          .firestore()
          .doc(`streams/${this.streamUid}/viewers/${this.uid}`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.userCountryCode = doc.data().location.country;
            }
          });
        // todo should we call create viewer here as well ?
      } else {
        this.firebase
          .auth()
          .signInAnonymously()
          .then((credentials) => {
            this.uid = credentials.user.uid;

            if (this.streamUid && credentials.user.uid) {
              // console.log('Creating viewer', this.streamUid, credentials.user.uid);
              createViewer(this.streamUid, credentials.user.uid).then((countryCode) => {
                this.userCountryCode = countryCode;
              });
            } else {
              // console.error('Missing data after anonL', this.streamUid, credentials.user.uid);
            }
          });
      }
    });

    this.firebase
      .firestore()
      .doc(`streams/${this.streamUid}`)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) {
          return console.error('Verse: Not a valid stream uid', this.streamUid);
        } else {
          this.validStreamUid = true;
        }
        const { isLiveStream, showEmojisOnPlayback, date_start, emojiBarClass } = snapshot.data();

        const streams = [];

        this.firebase
          .firestore()
          .doc(`streams/${this.streamUid}/stats/total`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.totalEmojis = doc.data();
              this.initialMoodScore = doc.data();
            }

            if (isLiveStream) {
              // For live stream we show other users emojis in real time
              this.addNewlyCreatedReactions();
              if (this.enableMoodEmojiBurst) {
                this.setupMoodEmojiBurst();
              }
            } else {
              // For non live stream we show what was clicked during the video
              this.telemetryHelper = new telemetryHelper(this.streamUid);
              // Do not auto start
              // this.setupBubbleInterval();
            }

            this.setState({
              origViews: streams,
              isLiveStream: isLiveStream,
              showEmojisOnPlayback: showEmojisOnPlayback,
              isRerunOfLiveStream: !isLiveStream && date_start,
              emojiBarClass: emojiBarClass,
            });
          });
      });
  }

  setOthersBubblesRef = (progression, ref) => {
    if (ref) {
      if (this.bubblesRefs[progression]) {
        this.bubblesRefs[progression].push(ref);
      } else {
        this.bubblesRefs[progression] = [];
        this.bubblesRefs[progression].push(ref);
      }
      // console.log('bubblesRefs[progression] length', this.bubblesRefs[progression].length);
    }
  };

  setNewlyAddedBubblesRef = (ref) => {
    if (ref) {
      this.newAddedBubblesRefs.push(ref);
      // console.log('newAddedBubblesRefs length', this.newAddedBubblesRefs.length);
    }
  };

  setMoodRef = (ref) => {
    if (ref) {
      this.moodBubblesRefs.push(ref);
      // console.log('moodBubblesRefs length', this.moodBubblesRefs.length);
    }
  };

  onFloatingAnimationDone = () => {
    const { floatingEmojis } = this.state;
    if (floatingEmojis.length !== 0) {
      floatingEmojis.shift();
      this.setState({
        floatingEmojis,
      });
    }
  };

  onEmojiBarToggle = () => {
    this.setState({
      isEmojiBarExpanded: !this.state.isEmojiBarExpanded,
    });
  };

  onSelect = ({ data }) => {
    data.seek = this.currentSecond;
    data.uid = this.uid;
    data.country = this.userCountryCode;

    // If livestream store telemetry indexed by unixtimestamp, otherwise save for current second into video
    data.live = this.state.isLiveStream;

    const { floatingEmojis } = this.state;
    const mergedEmojis = floatingEmojis;

    let mountedElement = document.querySelector(this.selectorId);

    mergedEmojis.push(
      <Emoji
        key={this.floatingEmojiCounter}
        onAnimationDone={() => this.onFloatingAnimationDone(this.floatingEmojiCounter)}
        type={emojiTypes[data.value].value}
        state="flow"
        mountedElementWidth={mountedElement.clientWidth}
      />
    );

    this.currentClickedEmoji = data;

    this.setState({
      floatingEmojis: mergedEmojis,
    });

    this.floatingEmojiCounter = this.floatingEmojiCounter + 1;

    this.firebase.firestore().collection(`streams/${this.streamUid}/views`).add(data);
  };

  formatBubbles = (docId, docData) => {
    const data = Object.entries(docData);
    // console.log('data in formatBub', data);
    const progression = docId;

    const bubbles = {
      [progression]: [],
    };

    for (let index = 0; index < data.length; index++) {
      const emojiIndex = data[index][0];
      if (emojiIndex === 'timestamp') continue;
      const bubbleAmount = data[index][1];
      // console.log('Bubble amount ', bubbleAmount);

      for (let i = 0; i < bubbleAmount; i++) {
        if (bubbles[progression].length <= this.maxBubblesAmount - 1) {
          // console.log('Bubble progreesion', bubbles[progression]);
          const newBubbles = this.createBubbles(
            `${progression}_${emojiIndex}_${i}`,
            progression,
            getColor(emojiIndex),
            'small',
            emojiIndex
          );
          if (!newBubbles) {
            continue;
          }
          bubbles[progression].push(newBubbles);
        }
      }
    }
    // console.log('returning done formatting bubs', bubbles);
    return bubbles;
  };

  /***
   * Listen for the total amount of each individual emoji clicked
   * If the emoji amount has increased then someone else clicked and we show them - unless it is us who clicked
   */
  addNewlyCreatedReactions = () => {
    this.firebase
      .firestore()
      .doc(`streams/${this.streamUid}/stats/total`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const newlyAdded = [];
          const newTotalEmojis = Object.entries(doc.data().total);

          // console.log("total", this.totalEmojis);
          // console.log("new", newTotalEmojis);

          // Only create emojis if EmojiBar is expanded
          // TODO this whole listener should be unsubscribed if EmojiBar is not expanded
          if (this.state.isEmojiBarExpanded) {
            for (let i = 0; i < newTotalEmojis.length; i++) {
              const emoji = newTotalEmojis[i];
              const currentEmojiIndex = parseInt(emoji[0]);
              const currentEmojiAmount = emoji[1];

              if (
                (this.totalEmojis[currentEmojiIndex] !== currentEmojiAmount && !this.currentClickedEmoji) ||
                (this.totalEmojis[currentEmojiIndex] !== currentEmojiAmount &&
                  this.currentClickedEmoji.value !== currentEmojiIndex)
              ) {
                // Check if max amount of emojis being rendered has been exceeded
                const amountExceeded = this.emojiHelper.isMaxAmountOfEmojisExceeded();
                // console.log('---------------------------------------------------------------------------------------');
                if (amountExceeded) continue;

                const newBubbles = this.createBubbles(
                  this.newlyAddedCounter,
                  `s_${Math.floor(new Date().getTime() / 1000)}`,
                  getColor(currentEmojiIndex),
                  'large',
                  currentEmojiIndex
                );
                if (!newBubbles) {
                  continue;
                }
                newlyAdded.push(newBubbles);
                this.newlyAddedCounter++;
              }
            }
          }

          this.totalEmojis = doc.data();

          if (newlyAdded.length !== 0) {
            this.setState(
              {
                newlyAddedView: [...this.state.newlyAddedView, ...newlyAdded],
              },
              () => {
                for (let i = 0; i < this.state.newlyAddedView.length; i++) {
                  const element = this.state.newlyAddedView[i];
                  const elementKey = element.key;
                  const newAddedRef = this.newAddedBubblesRefs.filter(
                    (bubble) => bubble.getAttribute('index') === elementKey
                  );
                  if (newAddedRef.length !== 0) {
                    newAddedRef[0].addEventListener(
                      animationEndEventName,
                      () => {
                        const copyMergedNewlyAddedEmojis = [...this.state.newlyAddedView];
                        const correctEmoji = copyMergedNewlyAddedEmojis.filter((emoji) => emoji.key === elementKey);
                        const indexOfCorrectEmoji = copyMergedNewlyAddedEmojis.indexOf(correctEmoji[0]);
                        if (indexOfCorrectEmoji !== -1) {
                          copyMergedNewlyAddedEmojis.splice(indexOfCorrectEmoji, 1);
                          this.setState(
                            {
                              newlyAddedView: copyMergedNewlyAddedEmojis,
                            },
                            () => {
                              this.newAddedBubblesRefs = this.newAddedBubblesRefs.filter(
                                (ref) => ref.getAttribute('index') !== elementKey
                              );
                              // console.log('newAddedBubblesRefs length', this.newAddedBubblesRefs.length);
                            }
                          );
                        }
                      },
                      { once: true }
                    );
                  }
                }
              }
            );
          }
        }
      });
  };

  /***
   * Listen for emoji's created for each second passes on a playback video and show if any emoji's are present
   */
  setupBubbleInterval() {
    if (this.bubbleInterval) {
      clearInterval(this.bubbleInterval);
    }
    this.bubbleInterval = setInterval(() => {
      const reset = this.resetPlaybackData;
      const isEmojiBarExpanded = this.state.isEmojiBarExpanded;

      const currSec = this.currentSecond;
      const currDocId = `s_${currSec}`;

      if (
        !this.bubbles[currDocId] &&
        this.telemetryHelper != null &&
        !this.state.isLiveStream &&
        (this.state.showEmojisOnPlayback || this.forceShowEmojisOnPlayback)
      ) {
        this.telemetryHelper
          .retrieveData(currSec, reset, isEmojiBarExpanded)
          .then((telemetryData) => {
            if (telemetryData) {
              // console.log('telementry data in EmojiStream', telemetryData);
              this.bubbles[currDocId] = this.formatBubbles(currDocId, telemetryData)[currDocId];
            }

            // Do not show emojis that has already been fetched because emoji bar is collapsed
            if (!isEmojiBarExpanded && this.bubbles[currDocId]) {
              this.bubbles[currDocId] = undefined;
            } else if (this.bubbles[currDocId]) {
              // console.log('Current bubbles ', this.bubbles[currDocId]);
              this.addOtherPeoplesEmojis(this.bubbles[currDocId], currSec);
            }
          })
          .catch((err) => {
            console.error('Error retrieving more emoji data. Stopping Telemetry', err);
            clearInterval(this.bubbleInterval);
          });
      }

      // Make sure we only call retrieve data with reset once
      this.resetPlaybackData = false;

      this.currentSecond = this.currentSecond + 1;
    }, 1000);
  }

  launchMoodBurst(currentEmojiIndex) {
    const moodEmojis = [];

    for (let index = 0; index < this.maxBubblesAmount * 3; index++) {
      const newBubbles = this.createBubbles(
        this.moodEmojiCounter,
        `s_${Math.floor(new Date().getTime() / 1000)}`,
        getColor(currentEmojiIndex),
        'small',
        currentEmojiIndex,
        true
      );
      if (!newBubbles) {
        continue;
      }
      moodEmojis.push(newBubbles);

      this.moodEmojiCounter = this.moodEmojiCounter + 1;
    }

    this.setState(
      {
        moodFloatingEmojis: moodEmojis,
      },
      () => {
        for (let i = 0; i < this.state.moodFloatingEmojis.length; i++) {
          const moodFloatingEmoji = this.state.moodFloatingEmojis[i];

          const moodFloatingEmojiKey = moodFloatingEmoji.key;

          const newAddedRef = this.moodBubblesRefs.filter(
            (bubble) => bubble.getAttribute('index') === moodFloatingEmojiKey
          );

          if (newAddedRef.length !== 0) {
            newAddedRef[0].addEventListener(
              animationEndEventName,
              () => {
                const copyMergedNewlyAddedEmojis = [...this.state.moodFloatingEmojis];

                const correctEmoji = copyMergedNewlyAddedEmojis.filter((emoji) => emoji.key === moodFloatingEmojiKey);

                const indexOfCorrectEmoji = copyMergedNewlyAddedEmojis.indexOf(correctEmoji[0]);

                if (indexOfCorrectEmoji !== -1) {
                  copyMergedNewlyAddedEmojis.splice(indexOfCorrectEmoji, 1);

                  this.setState(
                    {
                      moodFloatingEmojis: copyMergedNewlyAddedEmojis,
                    },
                    () => {
                      this.moodBubblesRefs = this.moodBubblesRefs.filter(
                        (ref) => ref.getAttribute('index') !== moodFloatingEmojiKey
                      );
                      // console.log('moodBubblesRefs length', this.moodBubblesRefs.length);
                    }
                  );
                }
              },
              { once: true }
            );
          }
        }
      }
    );
  }

  setupMoodEmojiBurst() {
    this.moodEmojiBurstInterval = setInterval(() => {
      this.firebase
        .firestore()
        .doc(`streams/${this.streamUid}/stats/total`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const totalData = Object.entries(doc.data());

            totalData.forEach((reactionData) => {
              const emojiIndex = parseInt(reactionData[0]);
              const reactionsAmount = reactionData[1];

              if (reactionsAmount - this.initialMoodScore[emojiIndex] >= this.maxBubblesAmount) {
                this.initialMoodScore[emojiIndex] = reactionsAmount;
                this.launchMoodBurst(emojiIndex);
              }
            });
          }
        });
    }, 2000);
  }

  createBubbles = (uniqueIndex, progression, color, size, emojiIndex, isMood) => {
    const duration = `${randomIntFromInterval(1, 4) + 4}s`;
    const left = randomIntFromInterval(1, 330);
    const bubbleSize = bubbleSizes[randomIntFromInterval(0, bubbleSizes.length - 1)];
    const emojiSize = emojiSizes[randomIntFromInterval(0, emojiSizes.length - 1)];

    let mountedElement = document.querySelector(this.selectorId);

    if (!mountedElement || !mountedElement.clientHeight) {
      return null;
    }

    let keyframes = `@keyframes floatingBubble-${uniqueIndex} {
            0% {
                left: ${left}px;
                bottom: 0;
            }
            25% {
                left: ${left - left * 0.1}px
            }

            50% {
                left: ${left}px;
            }

            75% {
                left: ${left - left * 0.2}px;
                opacity: 1;
            }

            100% {
                bottom: ${mountedElement.clientHeight}px;
                opacity: 0;
            }
        }`;

    const showEmojiInsteadOfBubble = this.emojiBubbleCounter % showEmojiInterval === 0;

    const style = {
      animationName: `floatingBubble-${uniqueIndex}`,
      animationDuration: duration,
      animationIterationCount: '1',
      animationTimingFunction: 'linear',
      left: `${left}px`,
      bottom: `0`,
      backgroundColor: color,
      height: size === 'small' ? (showEmojiInsteadOfBubble ? `${emojiSize}px` : `${bubbleSize}px`) : '30px',
      width: size === 'small' ? (showEmojiInsteadOfBubble ? `${emojiSize}px` : `${bubbleSize}px`) : '30px',
    };

    // Make sure we don't try to apply styles to an external stylesheet
    const styleSheet = Array.from(document.styleSheets).filter((s) => !s.href)[0];
    try {
      if (styleSheet && styleSheet.cssRules) {
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
      }
    } catch (e) {
      console.error('Verse error code 101: Styling could not be applied');
    }

    const props = {
      key: uniqueIndex,
      index: uniqueIndex,
      className: `verse-bubble`,
      style,
    };

    if (size === 'large' && !isMood) {
      props.ref = (ref) => this.setNewlyAddedBubblesRef(ref);
    } else if (size === 'small' && !isMood) {
      props.ref = (ref) => this.setOthersBubblesRef(progression, ref);
    } else if (isMood) {
      props.ref = (ref) => this.setMoodRef(ref);
    }

    this.emojiBubbleCounter = this.emojiBubbleCounter + 1;

    return showEmojiInsteadOfBubble || size === 'large' ? (
      <div {...props}>
        <img src={emojiTypes[emojiIndex].emoji} alt={emojiTypes[emojiIndex].name} />
      </div>
    ) : (
      <div {...props} />
    );
  };

  addOtherPeoplesEmojis = (bubbles, currSec) => {
    const currentSecond = currSec;
    const { otherPeoplesFloatingEmojis } = this.state;
    const mergedOtherPeopleEmojis = [...otherPeoplesFloatingEmojis, ...bubbles];

    this.setState(
      {
        otherPeoplesFloatingEmojis: mergedOtherPeopleEmojis,
      },
      () => {
        if (!this.bubblesRefs[`s_${currentSecond}`]) {
          return;
        }
        for (let index = 0; index < this.bubblesRefs[`s_${currentSecond}`].length; index++) {
          const bubbleRef = this.bubblesRefs[`s_${currentSecond}`][index];

          bubbleRef.addEventListener(
            animationEndEventName,
            (e) => {
              const target = e.target;
              const targetIndex = target.getAttribute('index');

              const copyMergedOtherPeopleEmojis = [...this.state.otherPeoplesFloatingEmojis];

              let correctIndex = undefined;

              for (let i = 0; i < copyMergedOtherPeopleEmojis.length; i++) {
                const emoji = copyMergedOtherPeopleEmojis[i];

                if (emoji.key === targetIndex) {
                  correctIndex = i;
                }
              }

              copyMergedOtherPeopleEmojis.splice(correctIndex, 1);
              this.setState(
                {
                  otherPeoplesFloatingEmojis: copyMergedOtherPeopleEmojis,
                },
                () => {
                  this.bubbles[`s_${currentSecond}`] = undefined;
                }
              );
              // console.log('Removed OtherPeoplesEmoji', this.bubblesRefs.length);
            },
            { once: true }
          );
        }
      }
    );
  };

  componentWillUnmount() {
    // console.log('UNMOUNTING PLUGIN');
    clearInterval(this.bubbleInterval);
    Emitter.off(Emitter.EVENTS.VIDEO_PROGRESS);
    Emitter.off(Emitter.EVENTS.VIDEO_STOP);
    this.bubblesRefs = [];
  }

  render() {
    const { floatingEmojis, otherPeoplesFloatingEmojis, newlyAddedView, moodFloatingEmojis } = this.state;

    // streamUid is set back to undefined if stream does not exist
    return this.validStreamUid ? (
      <div className="verse-stream-wrapper">
        <div className="verse-floatingEmojiContainer verse-floatingEmojiContainer--newAdded">{newlyAddedView}</div>
        <div className="verse-floatingEmojiContainer verse-floatingEmojiContainer--others">
          {otherPeoplesFloatingEmojis}
        </div>
        <div className="verse-floatingEmojiContainer verse-floatingEmojiContainer--mood">{moodFloatingEmojis}</div>
        <div className="verse-floatingEmojiContainer">{floatingEmojis}</div>
        {!this.hideEmojiControls ? (
          <EmotionBar
            onSelect={this.onSelect}
            onEmojiBarToggle={this.onEmojiBarToggle}
            isEmojiBarExpanded={this.state.isEmojiBarExpanded}
            align={this.align}
            emojiBarClass={this.state.emojiBarClass}
          />
        ) : null}
      </div>
    ) : null;
  }
}

export default EmojiStream;
